import { Row, Col } from "react-bootstrap";
import { SlArrowRight } from "react-icons/sl";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { ImMail4 } from "react-icons/im";
import { TfiSharethisAlt } from "react-icons/tfi";

export default function Sitemap () {
    const ulStyle = {
    listStyle: 'none',
    padding: 0,
  };

  const mainNavStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    padding: '10px',
  };

  const listItemStyle = {
    display: 'inline',
    marginRight: '10px',
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '20px',
  };

  const iconContainerStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const iconStyle = {
    fontSize: '24px',
    color: '#333',
  };

  const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
  };

  const nestedListStyle = {
    marginLeft: '20px',
  };

  const nestedListItemStyle = {
    marginBottom: '5px',
  };


  return (
    <div className="about-sl">
      <div className="content-topic">
        <ul className="public-style-content-subtopic">
          <li>
            <a href="/">
              Home <SlArrowRight />
            </a>
          </li>
         
          <li>
            <a href="/termsofparticipation">
              <b>Sitemap</b>{" "}
            </a>
          </li>
        </ul>
        <h1 className="topic-style-public">SITEMAP</h1>
      </div>
      <Row>
        <Col lg={2}>
          <div className="images-public-style">
            <ul className="new">
              <li>
                <a
                  className="logo-style"
                  href="https://www.facebook.com/ictasrilanka"
                >
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a
                  className="logo-style"
                  href="https://twitter.com/icta_srilanka"
                >
                  <AiFillTwitterCircle />
                </a>
              </li>
              <li>
                <a className="logo-style" href="https://www.icta.lk">
                  <ImMail4 />
                </a>
              </li>
              <li>
                <a className="logo-style" href="##">
                  {" "}
                  <TfiSharethisAlt />
                </a>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg={8}>
          <div className="content-body-top">
            <p className="font-style-public-p">
              <a  href="https://stagingicta.lankagate.gov.lk/"><b>Home</b></a> 
               <ul></ul>

                <b>Participate</b>
                <ul>
                    <li>
                    <a  href="https://stagingicta.lankagate.gov.lk/events">Events</a>
                    </li>
                    <li>
                     <a href='https://stagingicta.lankagate.gov.lk/ContentViews'>Public Consultation </a>
                    </li>
                </ul>
                <b>Read</b>
                <ul>
                    <li>
                        <a href="https://stagingicta.lankagate.gov.lk/newspress">
                    News and Press Releases
                    </a>
                    </li>
                    
                </ul>
                <a href="https://stagingicta.lankagate.gov.lk/photogallery"> <b> Photo Gallery</b></a>
                <ul></ul>
                <b>Who We Are</b>
                <ul>
                   <a href="https://stagingicta.lankagate.gov.lk/about"> <li>About ConnectSL</li></a>
                   <a href="https://stagingicta.lankagate.gov.lk/faqs"> <li>FAQs</li></a>
                   <a href="https://stagingicta.lankagate.gov.lk/usefullinks"><li>Useful Links</li></a>
                   <a href="https://stagingicta.lankagate.gov.lk/gfeedback"> <li>General Feedback</li></a>
                </ul>
                <a href="https://stagingicta.lankagate.gov.lk/contact-us"><b>Contact Us </b></a>
             </p>
            <br />
          </div>
        </Col>
        <Col lg={2}></Col>
      </Row>
    </div>
  );
}
