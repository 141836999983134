import React, { useEffect, useState } from "react";

import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { API_BASE_URL } from "../utils/constants";
import { SlArrowRight } from "react-icons/sl";
import moment from "moment";

import { Parser } from "html-to-react";

export default function Newspress() {
  // const [rssData, setRssData] = useState([]);
  const [rssData, setNews] = useState([]);

  useEffect(() => {
    const fetchAllContents = async () => {
      try {
        const res = await axios.get(`${API_BASE_URL}/getRssData`);
        setNews(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllContents();
  }, []);

  // useEffect(() => {
  //   const fetchAllContents = async () => {
  //     try {
  //       const res = await axios.get(` ${API_BASE_URL}/getRssData`);
  //       const xmlData = res.data;
  //       // console.log("a------------------->", res.data);
  //       const parser = new parseString.Parser({
  //         trim: true,
  //         explicitArray: false,
  //       });
  //       parser.parseString(xmlData, (err, result) => {
  //         if (err) {
  //           console.error("Error parsing XML:", err);
  //           return;
  //         }

  //         // Assuming your XML structure has an array of items under 'channel' and 'item'
  //         const items = result.rss.channel.item;
  //         setRssData(items);
  //       });
  //     } catch (err) {
  //       console.error("Error fetching RSS data:", err);
  //     }
  //   };

  //   fetchAllContents();
  // }, []);

  const handleCardClick = (link) => {
    window.open(link);
  };

  return (
    <Grid container spacing={2}>
      <div
        className="content-topic"
        style={{ marginLeft: "0%", marginTop: "1%" }}
      >
        <ul className="public-style-content-subtopic">
          <li>
            <a href="/">
              Home <SlArrowRight />
            </a>
          </li>
          <li>
            <a href="##">
              Read <SlArrowRight />
            </a>
          </li>
          <li>
            <a href="/newspress">
              <b>News and Press Releases</b>
            </a>
          </li>
        </ul>
        <h1 className="topic-style-public">NEWS AND PRESS RELEASES</h1>
      </div>
      {rssData.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Card
            onClick={() => handleCardClick(item.link)}
            style={{
              backgroundColor: "#eaeff1",
              height: "100%",
              transition:
                "transform 0.3s ease-in-out, background-color 0.3s ease-in-out",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Add shadow for better effect
              borderRadius: "8px",
              display: "flex",
              flexDirection: "row",
              // backgroundColor:"#f0f0f0",
              marginLeft: "157px",
              marginRight: "172px",
              // height:"auto", // Optional: Add border radius
            }}
            // Add hover effect styles
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              // e.currentTarget.style.backgroundColor = "#e0e0e0";
              e.currentTarget.style.zIndex = "1";
              e.currentTarget.style.backgroundColor = "#f0f0f0"; // Change to desired hover color
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.zIndex = "0";
              // e.currentTarget.style.backgroundColor = "#f0f0f0";
              e.currentTarget.style.backgroundColor = "#eaeff1"; // Change back to original color
            }}
            // style={{
            //   display: "flex",
            //   flexDirection: "row",
            //   backgroundColor: "#f0f0f0",
            //   transition: "background-color 0.3s",
            //   marginLeft: "157px",
            //   marginRight: "172px",
            //   height: "auto",
            // }}
            // onMouseOver={(e) =>
            //   (e.currentTarget.style.backgroundColor = "#e0e0e0")
            // }
            // onMouseOut={(e) =>
            //   (e.currentTarget.style.backgroundColor = "#f0f0f0")
            // }
          >
            <div
              style={{
                backgroundColor: "#239FB8",
                width: "115px",
                height: "auto",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#02343E")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#239FB8")
              }
            >
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Lato,sans-serif",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                {moment(item.pubDate).format("MMM")}

                <br></br>
                <b style={{ fontSize: "35px" }}>
                  {moment(item.pubDate).format("DD")}
                </b>

                <br></br>
                {moment(item.pubDate).format("YYYY")}
              </Typography>
            </div>
            <CardContent style={{}}>
              <Typography
                style={{
                  fontFamily: "Lato,sans-serif",
                  color: "#264A91",
                }}
                variant="h7"
                component="div"
              >
                News and Press Releases
              </Typography>
              <Typography
                style={{ fontFamily: "Lato,sans-serif" }}
                variant="h5"
                component="div"
              >
                <b>{new Parser().parse(item.title)}</b>
              </Typography>
              {/* <Typography
                style={{ fontFamily: "Lato,sans-serif" }}
                variant="h5"
                component="div"
              >
                <b>{new Parser().parse(item.description)}</b>
              </Typography> */}
            </CardContent>
          </Card>
          <br />
        </Grid>
      ))}
    </Grid>
  );
}
