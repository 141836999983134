import { Row, Col } from "react-bootstrap";
import { SlArrowRight } from "react-icons/sl";
import { FaFacebook } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { ImMail4 } from "react-icons/im";
import { TfiSharethisAlt } from "react-icons/tfi";
export default function termsofuse () {
  return (
    <div className="body-use-of-terms">
      <div className="content-topic">
        <ul className="public-style-content-subtopic">
          <li>
            <a href="/">
              Home <SlArrowRight />
            </a>
          </li>
          <li>
            <a href="##">
              Read <SlArrowRight />
            </a>
          </li>
          <li>
            <a href="/termsofuse">
              <b>Terms of USE</b>
            </a>
          </li>
        </ul>
        <h1 className="topic-style-public"> TERMS OF USE</h1>
      </div>
      <div>
        <Row>
          <Col lg={2}>
            <div className="images-public-style">
              <ul className="new">
                <li>
                  <a
                    className="logo-style"
                    href="https://www.facebook.com/ictasrilanka"
                  >
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a
                    className="logo-style"
                    href="https://twitter.com/icta_srilanka"
                  >
                    <AiFillTwitterCircle />
                  </a>
                </li>
                <li>
                  <a className="logo-style" href="https://www.icta.lk">
                    <ImMail4 />
                  </a>
                </li>
                <li>
                  <a className="logo-style" href="">
                    {" "}
                    <TfiSharethisAlt />
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={8}>
            <div className="content-use-of-terms">
              <p className="font-style-public-p">
                <b>TERMS OF USE FOR eParticipation PORTAL GOVERNMENT OF SRI LANKA</b>
              </p>
              <p className="font-style-public-p">
              Welcome to the eParticipation Portal, Government of Sri Lanka! We are excited to have you in our community and participate in the various activities and discussions offered on our platform. Before you get started, please take a moment to read and understand the applicable ‘Terms of Use’, as they govern your use of the portal.
By accessing or using the eParticipation Portal, you agree to comply with and be bound by these ‘Terms of Use’. If you do not agree with any part of these terms, you may refrain from accessing and using the platform.
<br/><br/>
<b>User Eligibility:</b>
You must be at least 18 years of age to use the eParticipation Portal. If you are under 18, you may access and use our platform only with the involvement and supervision of a parent or legal guardian.
              </p>
              <p className="font-style-public-p">
             <b>User Registration:</b>
In order to fully and actively participate in the activities and discussions on eParticipation Portal, entering accurate user details is a must. Name of the user and the respective contact details should be verifiable and administration has the rights to obtain verifications.
              </p>
              <p className="font-style-public-p">
               <b>User Conduct:</b>
You are allowed to use eParticipation Portal for lawful purposes only and in a manner consistent with all applicable laws and regulations of the country.
You will not be permitted to engage in any activity that disrupts the functioning of the platform or infringes the rights of others.
              </p>
              <p className="font-style-public-p">
              <b>Content Submission:</b>
By submitting content, such as comments, posts, or other materials, you grant eParticipation Portal, a non-exclusive, royalty-free, perpetual, irrevocable, and fully sub licensable right to use, reproduce, modify, adapt, publish, translate, create derivative work from, distribute, and display such content throughout the world in any media.
              </p>
              <p className="font-style-public-p">
              <b>Intellectual Property:</b>
All content and materials on eParticipation Portal, including but not limited to text, graphics, logos, images, and software, are the property of the Portal and are protected by copyright, trademark, and other intellectual property laws.
              </p>
              <p className="font-style-public-p">
             <b>Termination:</b>
The eParticipation Portal reserves the right to terminate or moderate your comments, for the best of the national interest.
              </p>
              <p className="font-style-public-p">
             <b>Changes to Terms of Use:</b>
eParticipation Portal, may update these ‘Terms of Use’ from time to time. We will notify you of any changes by updating the portal with the new ‘Terms of Use’. We expect your attentiveness towards respective changes and getting timely updated with the same.
              </p>
              <p className="font-style-public-p">
                <b></b>
              </p>
              <p className="font-style-public-p">
              <b>Contact Information:</b>
If you have any questions, clarifications or concerns about these ‘Terms of Use’, please refer the details in contact us.
By using eParticipation Portal, Government of Sri Lanka, you agree to these ‘Terms of Use’. Thank you for being a part of our community!
              </p>
            </div>
          </Col>
          <Col lg={2}></Col>
        </Row>
      </div>
    </div>
  );
}

