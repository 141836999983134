import { SlArrowRight } from "react-icons/sl";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { API_BASE_URL, API_IMG_URL } from "../utils/constants";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
// import Divider from "@mui/material/Divider";
// import ListItemText from "@mui/material/ListItemText";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PhotoGallery() {
  const [open, setOpen] = React.useState(false);
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);

  const handleClickOpen = async (gallery) => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/gallery/viewimages/${gallery.gallery_id}`
      );
      setGalleryImages(res.data);
      setSelectedGallery(gallery);
      setOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setSelectedGallery(null);
    setOpen(false);
  };

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const fetchAllContents = async () => {
      try {
        const res = await axios.get(`${API_BASE_URL}/gallery/viewgallery`);
        setGallery(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAllContents();
  }, []);

  return (
    <div className="body-photogallery">
      <div className="content-topic">
        <ul className="public-style-content-subtopic">
          <li>
            <a href="/">
              Home <SlArrowRight />
            </a>
          </li>
          <li>
            <a href="##">
              <b>Photo Gallery</b>{" "}
            </a>
          </li>
        </ul>
        <h1 className="topic-style-public">Photo Gallery</h1>
      </div>
      <br />
      <br />
      <div style={{ marginLeft: "70px" }}>
        <Row>
          <Col lg={1}></Col>
          <Col
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {gallery.map((gallery) => (
              <Col
                key={gallery.gallery_id}
                lg={4}
                md={6}
                sm={12}
                style={{ marginBottom: "20px" }}
              >
                <Card style={{ width: "18rem" }}>
                  <Card.Img
                    variant="top"
                    src={`${API_IMG_URL}/${gallery.image_path}`}
                    style={{ width: "100%", height: "200px" }}
                  />
                  <Card.Body>
                    <Card.Title style={{ textAlign: "center" }}>
                      <b>{gallery.gallery_title}</b>
                    </Card.Title>

                    <React.Fragment>
                      <Button
                        style={{ marginLeft: "20%" }}
                        variant="outlined"
                        onClick={() => handleClickOpen(gallery)}
                      >
                        Open Gallery
                      </Button>
                    </React.Fragment>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Col>
          <Col lg={1}></Col>
        </Row>
      </div>
      <br />
      <br />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedGallery ? selectedGallery.gallery_title : ""}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {galleryImages.map((photo) => (
            <ListItem key={photo.id}>
              <Card.Img
                variant="top"
                src={`${API_IMG_URL}/${photo.image_path}`}
                style={{ width: "100%", height: "auto" }}
              />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </div>
  );
}
