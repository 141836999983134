// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-muted {
  color: #aaa;
}

.link-muted:hover {
  color: #1266f1;
}`, "",{"version":3,"sources":["webpack://./src/styles/comtss.scss"],"names":[],"mappings":"AAAA;EAAc,WAAA;AAEd;;AAF6B;EAAoB,cAAA;AAMjD","sourcesContent":[".link-muted { color: #aaa; } .link-muted:hover { color: #1266f1; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
